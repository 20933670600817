import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import { ChevronLeft } from "@kiwicom/orbit-components/lib/icons";
import {
  Card,
  CardSection,
  Stack,
  Text,
  Box,
  Button,
  Heading,
  Loading,
} from "@kiwicom/orbit-components";

// Utils
import { getHorseById, getHorseImagesById } from "../../utils/api/horse";
// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Icons
import HorseIcon from "../../assets/icons/horse-head.png";
import MaleIcon from "../../assets/icons/male.png";
import FemaleIcon from "../../assets/icons/female.png";
// Types
import { Horse as HorseType } from "../../static/types/horse";
import { Image as ImageType } from "../../static/types/image";
// Styles
import { WysiwygContent } from "../../components/Wysiwyg/Styled";
// Utils
import translate from "../../utils/translate";

import {
  Content,
  Image,
  GalleryImage,
  HorsePriceImage,
  BackToCatalog,
  Gallery,
  HorseSexIcon,
} from "./Styled";

const HorseProfile = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [horse, setHorse] = useState<HorseType | null>(null);
  const [images, setImages] = useState<ImageType[]>([]);
  const { pathname } = useLocation();

  const horseId = pathname.split("/")[2];

  useEffect(() => {
    if (horseId) {
      // Fetchs blog post
      getHorseById(Number(horseId), setHorse, setLoading);
      // Fetchs blog post images
      getHorseImagesById(Number(horseId), setImages, setLoading);
    }
  }, [horseId]);

  return (
    <Wrapper>
      <Helmet>
        <title>{horse?.title}</title>
      </Helmet>
      <Content>
        {loading || !horse ? (
          <Container animate>
            <Loading type="pageLoader" />
          </Container>
        ) : (
          <Container animate>
            <Stack direction="column">
              <Stack
                direction="column"
                spacing="XXLarge"
                align="center"
                justify="center"
              >
                <Image
                  src={`${process.env.REACT_APP_STORAGE_BASE}/horse-thumb/${horse.image}`}
                  alt={horse.title}
                />
                <Heading type="display" align="center">
                  {horse.title}
                </Heading>
                <Card>
                  <CardSection>
                    <Stack direction="row" justify="between">
                      <Text weight="bold">
                        {translate("horse_profile.price")}
                      </Text>
                      <Stack
                        direction="row"
                        grow={false}
                        spacing="small"
                        inline
                      >
                        {[...Array(Number(horse.price))].map(() => (
                          <HorsePriceImage src={HorseIcon} />
                        ))}
                      </Stack>
                    </Stack>
                  </CardSection>
                  <CardSection>
                    <Stack direction="row" justify="between">
                      <Text weight="bold">
                        {translate("horse_profile.sex")}
                      </Text>
                      <HorseSexIcon
                        src={horse.sex === "m" ? MaleIcon : FemaleIcon}
                      />
                    </Stack>
                  </CardSection>
                  <CardSection>
                    <WysiwygContent
                      dangerouslySetInnerHTML={{
                        __html: horse.description,
                      }}
                    />
                  </CardSection>
                </Card>
              </Stack>
              {images.length > 0 && (
                <Card>
                  <CardSection>
                    <Gallery>
                      {images.map((image: ImageType) => (
                        <GalleryImage
                          src={`${process.env.REACT_APP_STORAGE_BASE}/horse/${image.path}`}
                          alt={image.title}
                        />
                      ))}
                    </Gallery>
                  </CardSection>
                </Card>
              )}
              {horse.video && (
                <Card>
                  <CardSection>
                    <iframe
                      width="100%"
                      height="400px"
                      src={`https://www.youtube.com/embed/${horse.video}`}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </CardSection>
                </Card>
              )}
              <Box
                width="100%"
                padding={{ left: "medium", right: "medium" }}
                tablet={{ padding: { left: "none", right: "none" } }}
              >
                <BackToCatalog
                  href={horse.type === "repnik" ? "/repnik" : "/catalog"}
                >
                  <Button type="white" iconLeft={<ChevronLeft />}>
                    <Stack direction="row">
                      {translate("horse_profile.back_to_catalog")}
                    </Stack>
                  </Button>
                </BackToCatalog>
              </Box>
            </Stack>
          </Container>
        )}
      </Content>
    </Wrapper>
  );
};

export default HorseProfile;
